import React from 'react';
import Particles from 'react-particles-js';
import cl from './Home.module.css';
import svg from '../../assets/svgs/gnomo2.svg';

function Home() {
  return (
    <div className={cl.Container}>
        <div style={{ backgroundImage: `url(${svg})` }} className={cl.Particles}>
          <Particles 
            params={{
              particles: {
                "collisions": {
                    "enable": true
                },
                "number": {
                    "value": 80,
                    "density": {
                        "enable": false
                    }
                },
                "line_linked": {
                    "enable": true,
                    "distance": 10,
                    "opacity": 0.4
                },
                "move": {
                    "speed": 2
                },
                "opacity": {
                    "anim": {
                        "enable": true,
                        "opacity_min": 0.05,
                        "speed": 1,
                        "sync": false
                    },
                    "value": .8
                }
            }
          }} />
        </div>
        <div className={cl.Paragraph}><h1>mauricioinaz</h1></div>
    </div>
  );
}

export default Home;

{/* <Particles 
params={{
  particles: {
    "collisions": {
        "enable": true
    },
    "number": {
        "value": 40,
        "density": {
            "enable": false
        }
    },
    "line_linked": {
        "enable": true,
        "distance": 10,
        "opacity": 0.4
    },
    "move": {
        "speed": .1
    },
    "opacity": {
        "anim": {
            "enable": true,
            "opacity_min": 0.05,
            "speed": 1,
            "sync": false
        },
        "value": 0.4
    }
}

}} /> */}

          // polygon: {
          //     enable: true,
          //     type: 'outside',
          //     url: svg
          //   },
          //   move: {
          //     type:  'path'
          //   },
          //   draw: {
          //     enable: true,
          //     stroke: {
          //       width: .4,
          //       color: 'rgba(230, 15, 15,.5)' 
          //     }
          //   }
import React, { Component } from 'react';
import cl from './Layout.module.css';
import tw from '../../assets/icons/tw.png'
import github from '../../assets/icons/github.svg'
//import DashToolbar from '../../components/Navigation/DashToolbar/DashToolbar';

class Layout extends Component {
  render() {

    //<NavLink to="/redes" exact activeClassName={cl.active}>Redes</NavLink>
    return (
      <>
      <header className={cl.Toolbar}>
          <nav >
          <div>
            <ul className={cl.NavigationItems}>
              <li className={cl.NavigationItem}>
                <div className={cl.Tuirer}>
                  <a href={"https://github.com/mauricioinaz"} target="_blank">
                    <img src={github} alt="github"/>
                  </a>
                </div>
              </li>
              <li className={cl.NavigationItem}>
                <div className={cl.Tuirer}>
                  <a href={"https://twitter.com/mauricioinaz"} target="_blank">
                    <img src={tw} alt="tuirer"/>
                  </a>
                </div>
              </li>
            </ul>
            </div>
          </nav>
      </header>
        <main>
            {this.props.children}
        </main>
      </>
    )
  }
}

export default Layout
